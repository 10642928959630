import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-drawer';

/**
 * @todo Move the close svg to it's own file, and import it using the raw-loader.
 * @param {Object} props
 * @returns {XML}
 * @constructor
 */
const Drawer = props => {
  const drawerProps = {
    className: `${applyModifiers(STYLE_PREFIX, [
      props.id,
      ...props.modifiers,
      // Only apply the new styling to navigation, not bag
      props.id === 'bag' || props.id === 'stock-finder'
        ? ''
        : 'navigation-newStyling',
    ])}`,
    id: props.id,
  };

  if (props.isVisible) {
    drawerProps['data-drawer-visible'] = props.id;
  }

  if (props.drawerRef) {
    drawerProps.ref = props.drawerRef;
  }

  return (
    <section {...drawerProps}>
      <div className="c-drawer__header">
        <h1 className="c-drawer__title">{props.title}</h1>
        {props.header}
        <button
          onClick={e => {
            e.preventDefault();
            props.onClose();
          }}
          className="c-action c-action--dismiss"
          aria-controls={props.id}
        >
          <span className="c-icon c-icon--dismiss--large c-icon--label-after">
            <svg
              className="c-icon__glyph"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 240 240"
              role="img"
            >
              <g transform="rotate(45, 120, 120)">
                <rect x="-20" y="110" width="280" height="24" rx="12" />
                <rect x="110" y="-20" width="24" height="280" rx="12" />
              </g>
            </svg>
            <span className="c-icon__label">
              <FormattedMessage id="actions.close" defaultMessage="Close" />
            </span>
          </span>
        </button>
      </div>
      <div
        className={`c-drawer__main ${
          props.id === 'bag' || props.id === 'stock-finder'
            ? ''
            : 'c-drawer__main--newStyling'
        }`}
      >
        {props.children}
      </div>
    </section>
  );
};

Drawer.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  header: PropTypes.element,
  modifiers: PropTypes.array,
  onClose: PropTypes.func,
  title: PropTypes.string,
  locale: PropTypes.object,
  drawerRef: PropTypes.object,
};

Drawer.defaultProps = {
  isVisible: false,
  modifiers: [],
  onClose: () => {},
  title: 'Menu',
};

export default Drawer;
