// @flow
import React from 'react';
import { connect } from 'react-redux';
import core from '@bravissimolabs/website-core';
import BrastrapBag, {
  type Props as BrastrapBagProps,
} from 'brastrap/transactional/bag/Bag';
import Messages from 'brastrap/containers/messages/MessagesContainer';
import {
  apiRemoveFromBag,
  apiUpdateQuantity,
  receiveBag,
  getBagIdentifier,
} from '../actions/bag';
import { receivePromotions } from '../actions/promotions';

import { CLOSE_POPOVER_BAG_TIMER } from '../constants/timers';
import { cancelTimer } from '../actions/timers';

const Bag = (props: BrastrapBagProps) => (
  <div>
    <Messages target="bag" />
    <BrastrapBag {...props} />
  </div>
);

/**
 * Get the bag contents and dispatch them once they have been received.
 *
 * @todo check we are using the customer's email as a customer id.
 *
 * @param {object} store
 * @param {object} props
 * @param {object} req
 *
 * @returns {*}
 */
Bag.populateStore = async (store, props, req) => {
  const bagApi = core.customer.bag;
  const bagIds = getBagIdentifier(req);

  const systemApi = core.system.system;

  const promotions = await systemApi.getActivePromotionsWithMessages();
  if (promotions && promotions.length) {
    store.dispatch(receivePromotions(promotions));
  }
  // When populating the store from the server we will not have a customer id
  // We might have a customer id in the store though.
  const auth = store.getState().auth;
  if (!bagIds.customerId && auth.isIdentified && auth.user && auth.user.id) {
    bagIds.customerId = auth.user.id;
  }

  try {
    const bag = await bagApi.getDecoratedBag(
      bagIds.customerId || bagIds.browserId
    );
    return store.dispatch(receiveBag(bag));
  } catch (error) {
    return error;
  }
};

const mapDispatchToProps = dispatch => ({
  onDelete: code => {
    dispatch(apiRemoveFromBag(code));
    dispatch(cancelTimer(CLOSE_POPOVER_BAG_TIMER));
  },
  onChange: (code, quantity) => {
    dispatch(apiUpdateQuantity(code, quantity));
    dispatch(cancelTimer(CLOSE_POPOVER_BAG_TIMER));
  },
});

const mapStateToProps = ({ app, bag, promotions, checkout }) => {
  return {
    ...bag,
    currency: app.config.currency,
    isEditable: !app.routing.pathname.includes('checkout'),
    promotions,
    checkout,
    locale: app.locale.selected,
    usTaxAmount: bag.usTaxAmount,
  };
};

const flatten = (all, current) => [...all, ...current];
const hasCurrentLocation = currentLocation => message =>
  message.displayLocations.find(location => location === currentLocation);

const mergeProps = (state, dispatch, ownProps) => ({
  ...state,
  promotionMessages: Object.keys(state.promotions)
    .map(key => state.promotions[key].messages)
    .reduce(flatten, [])
    .filter(hasCurrentLocation(ownProps.location)),
  ...dispatch,
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Bag);

export const UnwrappedBag = Bag;
