import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import { forceCheck } from 'react-lazyload';
import { connect } from 'react-redux';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import Drawer from 'brastrap/global/drawer/Drawer.jsx';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import { dataLayerGA4ViewCart } from '../../../../shared/utils/data-layer';

const messages = defineMessages({
  checkout: {
    id: 'drawer-bag.checkout',
    defaultMessage: 'Proceed to checkout',
  },
  title: { id: 'drawer-bag.title', defaultMessage: 'Your bag' },
});

const DrawerBag = props => {
  // Because the drawer has it's own overflow-y, React LazyLoad can't calculate
  // when images enter the DOM. As a result we have to manually specify the component
  // ref, and force re-checks of that component on scroll.
  // If this doesn't happen, images after the top few will not render and just be stuck
  // with the spinner.

  const drawerRef = useRef(null);
  useEffect(() => {
    const drawer = drawerRef.current;
    const handleScroll = () => {
      forceCheck();
    };

    if (drawer) {
      drawer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (drawer) {
        drawer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const drawerProps = {
    id: 'bag',
    isVisible: props.isVisible,
    modifiers: ['right'],
    onClose: props.onClose,
    title: props.intl.formatMessage(messages.title),
    drawerRef: drawerRef,
  };

  useEffect(() => {
    if (props.isVisible) {
      dataLayerGA4ViewCart(props.currency, props.bagData);
    }
  }, [props.isVisible, props.currency, props.bagData]);

  return (
    <Drawer {...drawerProps}>
      {props.bag}
      {props.displayCheckoutLink && (
        <div className="u-center">
          <ButtonLink
            modifiers={['checkout', 'filled', 'primary']}
            label={props.intl.formatMessage(messages.checkout)}
            url="/checkout"
          />
        </div>
      )}
    </Drawer>
  );
};

DrawerBag.propTypes = {
  displayCheckoutLink: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  bag: PropTypes.element.isRequired,
  intl: intlShape,
  bagData: PropTypes.object,
  currency: PropTypes.string,
};

DrawerBag.defaultProps = {
  isVisible: false,
  onClose: () => {},
};

const mapStateToProps = state => ({
  bagData: state.bag,
  currency: state.app.config.currency,
});

export default injectIntl(connect(mapStateToProps)(DrawerBag));
