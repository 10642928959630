import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from 'brastrap/helpers/l-grid/l-grid';
import UtilityNav from 'brastrap/common/utility-nav/UtilityNav';
import Collections from 'brastrap/global/collections/Collections';
import EditorialBlock from 'brastrap/editorial/editorial-block/EditorialBlock';
import ShopBy from 'brastrap/global/shop-by/ShopBy';
import { applyModifiers } from '../../utils';

const Column = Grid.Unit;

const MegaMenuHeader = ({
  onClose,
  menuId,
  className,
  onBackButtonClick,
  isDesktop,
}) => (
  <div className={className}>
    <button
      className="c-action c-action--left"
      onClick={() =>
        onBackButtonClick(
          !isDesktop ? 'shop' : null
        )
      }
    >
      <span className="c-icon__label">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 120 240"
        >
          <path d="M4 112c-4 4-4 12 0 16l100 100c4 4 9 3 12 0s4-8 0-12l-96-96 96-96c4-4 3-9 0-12s-8-4-12 0L4 112z" />
        </svg>
        <FormattedMessage id="actions.back" defaultMessage="Back" />
      </span>
    </button>
    <button
      type="button"
      onClick={onClose}
      className="c-action c-action--dismiss"
      aria-controls={menuId}
    >
      <span className="c-icon c-icon--dismiss--large c-icon--label-after">
        <svg
          className="c-icon__glyph"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 240 240"
          role="img"
        >
          <g transform="rotate(45, 120, 120)">
            <rect x="-20" y="110" width="280" height="24" rx="12" />
            <rect x="110" y="-20" width="24" height="280" rx="12" />
          </g>
        </svg>
      </span>
    </button>
  </div>
);

MegaMenuHeader.propTypes = {
  menuId: PropTypes.string,
  onClose: PropTypes.func,
  onBackButtonClick: PropTypes.func,
  className: PropTypes.string,
  isDesktop: PropTypes.bool,
};

const MegaMenu = ({
  id,
  columns,
  gridModifiers,
  showContent,
  isVisible,
  label,
  onClose,
  onBackButtonClick,
  sizes,
  shopBy,
  accordion,
  displayMegaMenuHeader,
  isDesktop,
}) => {
  const STYLE_PREFIX = 'c-mega-menu';
  const className = `${STYLE_PREFIX}${isVisible ? ' is-visible' : ''}`;
  const menuId = `${id}-menu`;

  return (
    <div
      className={`${className} ${className}--newStyling`}
      id={menuId}
      role="menu"
      aria-labelledby={id}
    >
      <section className={`${STYLE_PREFIX}__body`}>
        {displayMegaMenuHeader && (
          <MegaMenuHeader
            {...{
              menuId,
              className: `${STYLE_PREFIX}__item`,
              onClose,
              onBackButtonClick,
              isDesktop,
            }}
          />
        )}
        <div
          className={`${STYLE_PREFIX}__body--inner ${
             !isDesktop
              ? `${STYLE_PREFIX}__body--inner--mobile-newStyling`
              : ''
          }`}
        >
          {shopBy ? (
            <div
              className={`${STYLE_PREFIX}__item ${STYLE_PREFIX}__item--shop-by`}
            >
              <div className={`${STYLE_PREFIX}__title`}>{label}</div>
              <ShopBy accordion={accordion} sizes={sizes} />
            </div>
          ) : (
            // We can wrap the mega menu items in the Grid component and leverage its flexibility/responsiveness
            // TODO: as a possible enhancement, we can provide weighting options in Contentful to allow the
            // marketing department to assign more bespoke distribution to menu items/content
            <Grid modifiers={gridModifiers}>
              {columns.map((column, position) => {
                let columnModifier = '';
                let content = null;

                switch (true) {
                  case column.collections && column.collections.length > 0:
                    columnModifier = 'collections';
                    content = <Collections {...column} />;
                    break;

                  case Array.isArray(column.linkGroups) &&
                    column.linkGroups.length > 0:
                    columnModifier = 'utilities';
                    content = column.linkGroups.map(nav => (
                      <UtilityNav key={nav.id} modifiers={['menu']} {...nav} />
                    ));
                    break;

                  default:
                    break;
                }

                if (
                  Array.isArray(column.contentBlocks) &&
                  column.contentBlocks.length > 0 &&
                  showContent
                ) {
                  content = (
                    <div>
                      {content}
                      {column.contentBlocks.map(contentBlock => (
                        <EditorialBlock
                          key={contentBlock.id}
                          content={contentBlock.content}
                        />
                      ))}
                    </div>
                  );
                }

                return (
                  content && (
                    <Column key={column.id}>
                      <div
                        className={applyModifiers(`${STYLE_PREFIX}__item`, [
                          columnModifier,
                          String(position),
                        ])}
                      >
                        {content}
                      </div>
                    </Column>
                  )
                );
              })}
            </Grid>
          )}
        </div>
      </section>
    </div>
  );
};

MegaMenu.propTypes = {
  id: PropTypes.string,
  gridModifiers: PropTypes.arrayOf(PropTypes.string),
  showContent: PropTypes.bool,
  isVisible: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      collections: PropTypes.array,
      linkGroups: PropTypes.array,
      contentBlocks: PropTypes.array,
    })
  ).isRequired,
  label: PropTypes.string,
  sizes: PropTypes.object,
  shopBy: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  accordion: PropTypes.bool,
  displayMegaMenuHeader: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

MegaMenu.defaultProps = {
  isVisible: false,
  columns: [],
};

export default MegaMenu;
