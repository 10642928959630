// @flow
import React from 'react';
import InstagramFeed from 'brastrap/editorial/instagram-feed/InstagramFeed';
import Helmet from 'react-helmet';

const WrappedInstagramFeed = () => {
  const helmet = (
    <Helmet
      style={[
        {
          id: 'instagramFeed',
          cssText: `.fs-has-links::after{
              padding: 8px 12px;
              background-color: #fff;
              color: rgb(234, 81, 114);
              content: "See more";
          }
          .fs-entry-container {
              width: 400px !important;
              height: 400px !important;
          }
          path.fs-arrow {
              stroke: #222;
          }
          .fs-wrapper div.fs-text-container .fs-entry-title, div.fs-detail-title {
              font-family: Enkel;
              font-style: normal;
              font-weight: normal;
          }
          div.fs-text-container .fs-entry-date,
          div.fs-detail-container .fs-post-info,
          div.fs-wrapper div.fs-has-links::after,
          .fs-text-product, .fs-overlink-text {
              font-family: Enkel;
              font-style: normal;
              font-weight: bold;
          }
          .fs-slider-next-button, .fs-slider-prev-button {
              opacity: 1;
          }
          .fs-wrapper div.fs-text-container * {
              color: #fff
          }`,
        },
      ]}
    />
  );
  return (
    <div>
      {helmet}
      <InstagramFeed />
    </div>
  );
};

export default WrappedInstagramFeed;
