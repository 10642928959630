// @flow
import React from 'react';
import ReturnNav from 'brastrap/common/return-nav/ReturnNav';
import type { Bandeau as Props } from './bandeau.types';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-bandeau';
const Bandeau = (props: Props) => {
  const className = applyModifiers(STYLE_PREFIX, props.modifiers);

  return (
    <header className={className}>
      <div
        className={
          props.returnNav || props.children
            ? 'c-bandeau__body'
            : `c-bandeau__body--full-width`
        }
      >
        <div className="c-bandeau__header">
          <h1 className={`${props.className || 'c-bandeau__title'}`}>
            {props.title}
          </h1>
          {props.desc && <p className="c-bandeau__desc">{props.desc}</p>}
        </div>
        <div className="c-bandeau__nav">
          {props.returnNav && <ReturnNav {...props.returnNav} />}
        </div>
        <div className="c-bandeau__aside">{props.children}</div>
      </div>
      {props.tabs && <div className="c-bandeau__tabs">{props.tabs}</div>}
    </header>
  );
};

export default Bandeau;
