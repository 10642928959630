/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import core from '@bravissimolabs/website-core';
import NewFooterComponent from 'brastrap/global/new-footer/NewFooter.jsx';
import ContentInfo from 'shared/containers/ContentInfoContainer';
import Modal from 'shared/containers/ModalContainer';
import receiveSettingGroupData from 'shared/actions/footer';
import { getSettingsSortByOrderNumber } from '../utils/utils';

const Footer = props => {
  return (
    <>
      <NewFooterComponent {...props}>
        <ContentInfo />
        <Modal />
      </NewFooterComponent>
    </>
  );
};

/**
 * @param {Object} store
 * @param {Object} props
 * @param {Object} req
 * @returns {Object} settingGroup
 */
Footer.populateStore = async (store, props, req) => {
  await ContentInfo.populateStore(store, props, req);
  await Modal.populateStore(store, props, req);
  const footerApi = core.content.footer;
  try {
    const settingGroup = await footerApi.getFooterSettingGroup();
    return store.dispatch(receiveSettingGroupData(settingGroup));
  } catch (error) {
    return error;
  }
};

const mapStateToProps = state => ({
  locale: state.app.locale.selected,
  containers: state.footer.siteFooterContainers || [],
  navs: state.footer.siteFooterNavs || [],
  social: state.footer.social || {},
  signUp: state.footer.siteFooterSignUp || [],
  securePayments: getSettingsSortByOrderNumber(
    state.footer.securePayments || {}
  ),
  awards: state.footer.awards || {},
  isMobile: state.browser.deviceIs.mobile,
});

export default connect(mapStateToProps)(Footer);
