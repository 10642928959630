// @flow
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-sticker';

type Currency = string;
type Reduction = number;
type ReductionType = 'percentage' | 'value';

type Label = {
  currency?: Currency,
  reduction: Reduction,
  type: ReductionType,
};

type Props = {
  currency?: Currency,
  label: string,
  modifiers: Modifiers,
  reduction?: Reduction,
  type: ReductionType,
  roundals: {
    promo: {
      backgroundColour: string,
      textColour: string,
    },
  },
};

type Attributes = {
  className: string,
  style?: {
    'background-color'?: string,
    color?: string,
  },
};

const PromotionLabel = ({ currency, reduction, type }: Label) => {
  if (type === 'percentage') {
    const percentage = reduction;
    return (
      <FormattedMessage
        id="sticker.promo.label-percentage"
        defaultMessage="{percentage}% off"
        values={{ percentage }}
      />
    );
  }
  const amount = (
    <FormattedNumber
      value={reduction}
      maximumFractionDigits={0}
      minimumFractionDigits={0}
      style="currency"
      currency={currency}
    />
  );
  return (
    <FormattedMessage
      id="sticker.promo.label-value"
      defaultMessage="{amount} off"
      values={{ amount }}
    />
  );
};

const Sticker = (props: Props) => {
  const className = applyModifiers(STYLE_PREFIX, props.modifiers);
  const roundalAttributes: Attributes = { className };
  const labelAttributes: Attributes = { className: 'c-sticker__label' };

  if (props.modifiers.includes('promo')) {
    const {
      promo: { backgroundColour, textColour },
    } = props.roundals;
    roundalAttributes.style = { 'background-color': backgroundColour };
    labelAttributes.style = { color: textColour };
  }
  // Generate a promotion label on the fly if a label does not exist and we have all the ingredients.
  const label =
    (props.type === 'value' || props.type === 'percentage') &&
    props.reduction &&
    props.currency &&
    !props.label ? (
      <PromotionLabel {...props} />
    ) : (
      props.label
    );

  return props.reduction || props.label ? (
    <p {...roundalAttributes}>
      <em {...labelAttributes}>{label}</em>
    </p>
  ) : null;
};

Sticker.defaultProps = {
  modifiers: [],
  roundals: {
    promo: {
      backgroundColour: '#243bbb',
      textColour: '#ffffff',
    },
  },
};

export default Sticker;
