// @flow
import React from 'react';
import MarkDown from 'brastrap/editorial/markdown/Markdown';
import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import Link from 'brastrap/containers/link/LinkContainer';
import type { Channel as Props } from './channel.types.js';

const Channel = (props: Props) => {
  const mainAction = props.actions && props.actions.length && props.actions[0];
  const hasDummyButton = !!mainAction;

  const channel = (
    <section className="c-channel c-channel--newStyling">
      <header className="c-channel__header c-channel__header--newStyling">
        {props.img && props.img.src && (
          <img
            className="c-channel__img c-channel__img--newStyling"
            src={props.img.src}
            alt={props.img.alt}
            height="48"
          />
        )}
        {props.title && (
          <h2 className="c-channel__title c-channel__title--newStyling">
            {props.title}
          </h2>
        )}
        <div className="c-channel__desc c-channel__desc--newStyling">
          {props.introduction && <MarkDown content={props.introduction} />}
        </div>
      </header>
      {props.actions.length && (
        <div className="c-channel__actions c-channel__actions--newStyling">
          {props.actions.map((action, key) => (
            <ButtonLink
              isDummyButton={hasDummyButton}
              modifiers={['filled', 'small']}
              key={key}
              {...action}
            />
          ))}
        </div>
      )}
    </section>
  );

  return mainAction && mainAction.link ? (
    <Link {...mainAction.link} href={mainAction.link.url}>
      {channel}
    </Link>
  ) : (
    channel
  );
};

Channel.defaultProps = {
  actions: [],
};

export default Channel;
