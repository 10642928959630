import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import isFunction from 'lodash/isFunction';

import BodyClass from '../components/BodyClass';
import { closeLayer } from '../actions/layer';

const BACKDROP_STYLE_PREFIX = 'u-backdrop';
const LAYER_STYLE_PREFIX = 'is-layer';

export const applyModifiers = (base, modifiers) =>
  `${base}${modifiers.map(m => ` ${base}--${m}`).join()}`;

const Backdrop = props => {
  let content = null;
  const modifiers = [
    ...props.modifiers,
    !props.closeOnOutsideClick && 'disabled',
  ];
  const backdropClassName = applyModifiers(BACKDROP_STYLE_PREFIX, modifiers);

  const layerClassName = `${applyModifiers(
    LAYER_STYLE_PREFIX,
    props.modifiers
  )} ${
    props.modifiers.includes('mega-menu') && props.isMobile
      ? `${LAYER_STYLE_PREFIX}--mega-menu-mobile-newStyling`
      : ''
  }`;

  if (props.target) {
    content = (
      <BodyClass key="backdrop" className={layerClassName}>
        <button
          onClick={e => {
            e.preventDefault();
            if (props.closeOnOutsideClick) {
              props.onClose(props.target);
            }
          }}
          className={backdropClassName}
          role="button"
          aria-controls={props.target}
        />
      </BodyClass>
    );
  }

  return (
    <CSSTransitionGroup
      transitionName="u-backdrop"
      transitionAppear
      transitionAppearTimeout={300}
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
    >
      {content}
    </CSSTransitionGroup>
  );
};

Backdrop.propTypes = {
  target: PropTypes.string,
  onClose: PropTypes.func,
  modifiers: PropTypes.array,
  closeOnOutsideClick: PropTypes.bool,
  currentLocale: PropTypes.string,
  isMobile: PropTypes.bool,
};

Backdrop.defaultProps = {
  modifiers: [],
  closeOnOutsideClick: true,
};

const mapStateToProps = ({
  layer,
  browser: { deviceIs },
  app: { locale },
}) => ({
  ...layer,
  isMobile: deviceIs.mobile,
  currentLocale: locale.selected,
});

const mergeProps = (state, { dispatch }) => ({
  ...state,
  onClose: () => {
    // TODO investigate why we are not getting the onClose event when set via PopulateStore.
    // Check the state for an onClose method.
    const { onClose } = state;
    // If one exists then call it
    if (onClose && isFunction(onClose)) {
      onClose();
    }

    // Dispatch the close layer action to close the backdrop.
    dispatch(closeLayer());
  },
});

export default connect(mapStateToProps, null, mergeProps)(Backdrop);

export const UnwrappedBackdrop = Backdrop;
