// @flow
import React, { Component, type Node, type Element } from 'react';
import {
  defineMessages,
  FormattedMessage,
  FormattedNumber,
  injectIntl,
  type IntlShape,
} from 'react-intl';
import { forceCheck } from 'react-lazyload';
import { findKey, pick } from 'lodash';
import Button from 'brastrap/common/button/Button';
import PromotionMessage from 'brastrap/promotional/promotion-message/PromotionMessage';
import BagItem from './BagItem';
import BagItemMessage from './BagItemMessage';
import { applyModifiers } from '../../utils';

const STYLE_PREFIX = 'c-bag';

const messages = defineMessages({
  summary: { id: 'bag.table.summary', defaultMessage: 'Items in bag' },
});

/**
 * @constant
 * @type {{warehouse: number, shop: number, unknown: number}}
 */
export const LOCATION_STOCK_GROUP_MAP = {
  warehouse: 1,
  shop: 2,
  unknown: 3,
};

type LocationType = 'warehouse' | 'shop' | 'unknown';

type TitleMessages = {
  shop: Node,
  unknown: Node,
  warehouse: Node,
};

/**
 * @param {String} location
 * @param {Array} items
 * @returns {Boolean}
 */
const hasItemsLocatedIn = (location = 'warehouse', items) =>
  !!(
    items[LOCATION_STOCK_GROUP_MAP[location]] &&
    items[LOCATION_STOCK_GROUP_MAP[location]].length
  );

/**
 * @param {String} location
 * @param {Array} items
 * @param {Object} titleMessages
 * @param {Boolean} upSellPremiumPostage
 * @param {Function} onUpSellPostage
 * @returns {Array}
 */
const getItemsLocatedIn = (
  location: LocationType = 'warehouse',
  items: Element<*>[][],
  titleMessages: TitleMessages,
  upSellPremiumPostage: boolean = false,
  onUpSellPostage: () => void = () => {}
): Array<?Node> => {
  const bagItems = items[LOCATION_STOCK_GROUP_MAP[location]];
  const titleMessage = titleMessages && titleMessages[location];

  return hasItemsLocatedIn(location, items)
    ? [
        <BagItemMessage key={location}>{titleMessage}</BagItemMessage>,

        // If they are available for next day delivery, then add the upgrade postage text.
        upSellPremiumPostage && (
          <BagItemMessage key="upgrade-postage" modifiers={['upgrade']}>
            <Button
              modifiers={['small', 'upgrade-postage']}
              label="Upgrade"
              onClick={onUpSellPostage}
            />
            <FormattedMessage
              id="bag.premium-delivery.upsell"
              defaultMessage="Need them sooner?"
            />
          </BagItemMessage>
        ),

        ...bagItems,
      ]
    : [];
};

export type Props = {
  currency: string,
  delivery: {
    postage: ?number,
    discount: number,
  },
  disableClickableThumbnails: boolean,
  intl: IntlShape,
  displaySaveItemButton: boolean,
  isDomestic: boolean,
  isEditable: boolean,
  isPremiumAvailable: boolean,
  items: BagItemType[],
  onDelete: (code: string) => void,
  onChange: (code: string, quantity: string) => void,
  onSaveItemPress: ({
    styleColourCode: string,
    skuCode: string,
    styleCode: string,
  }) => void,
  savedItemsMap: ItemMap,
  onUpSellPostage: () => void,
  payment: {
    card: Token,
    giftVouchers: {
      reference: string,
      value: number,
    }[],
  },
  premium: boolean,
  promotionMessages: {
    displayLocations: string[],
    message: PromotionMessageType,
  }[],
  showPostage: boolean,
  splitItemsByDeliveryTimes: boolean,
  subTotal: number,
  titleMessages: TitleMessages,
  total: number,
  usTaxAmount: number,
  locale: string,
  showUSTax: boolean,
  location: string,
};

/**
 * @param {Object} props
 * @return {XML}
 * @constructor
 */
class Bag extends Component<Props> {
  static defaultProps = {
    displaySaveItemButton: false,
    delivery: {
      methodLabel: null,
      postage: null,
      discount: 0,
    },
    quantity: {
      options: [],
    },
    isEditable: false,
    items: [],
    payment: {
      giftVouchers: [],
    },
    onChange: () => {},
    onDelete: () => {},
    onUpSellPostage: () => {},
    premiumUpgradeId: 'next',
    promotionMessages: [],
    showPostage: false,
    currency: 'GBP',
    disableClickableThumbnails: false,
  };
  static Item = BagItem;
  static ItemMessage = BagItemMessage;

  /**
   * @return {*}
   */
  render() {
    if (!(this.props.items && this.props.items.length > 0)) {
      return (
        <div id="bag-empty">
          <FormattedMessage
            id="bag.empty"
            defaultMessage="You have no items in your bag"
          />
        </div>
      );
    }

    const {
      delivery: { postage, discount = 0 },
      currency,
      intl: { formatMessage },
      isDomestic,
      isEditable,
      isPremiumAvailable,
      premium,
      items,
      titleMessages,
      onUpSellPostage,
      payment: { giftVouchers = [] },
      promotionMessages,
      showPostage,
      splitItemsByDeliveryTimes,
      subTotal,
      total,
      usTaxAmount,
      locale,
      showUSTax,
    } = this.props;

    // TODO: Refactor this logic (reused in several places) - UST timeline hasn't allowed time.
    // GVs make US tax hard!
    // TL;DR - the way that GVs work is to reduce the order.total by the value of
    // the GV. However, the order.total does not include tax (as defined by the
    // API contract and orderXml SP). This doesn't work for US orders that attract
    // sales tax, as the order and delivery get paid for by GV, but the tax does not!
    // This logic below means we calculate a special `totalUsAmount` to ensure
    // usTax is included in the total, even when GVs are used!
    let totalUSAmount;
    if (this.props.payment.giftVouchers) {
      const itemTotals = this.props.items.reduce(
        (itemTotal, item) => itemTotal + item.price * item.quantity,
        0
      );
      const postagePrice = this.props.delivery.postage || 0;
      const postageDiscount = this.props.delivery.discount || 0;
      const postageTotal = postagePrice - postageDiscount;

      const gvTotal = this.props.payment.giftVouchers.reduce(
        (giftVoucherTotal, gv) => giftVoucherTotal + gv.value,
        0
      );

      totalUSAmount = Math.max(
        itemTotals + postageTotal + usTaxAmount - gvTotal,
        0
      );
    } else {
      totalUSAmount = total + usTaxAmount;
    }

    const cols = isEditable ? 4 : 3;
    const upSellPremiumPostage = isPremiumAvailable && !premium;

    const bagItemProps = pick(
      this.props,
      'currency',
      'isEditable',
      'onChange',
      'onDelete',
      'disableClickableThumbnails',
      'displaySaveItemButton',
      'onSaveItemPress',
      'savedItemsMap'
    );

    const getBagItemElement = (item, stockGroup) => {
      const modifiers = [
        // Add the stock group name to the modifiers array
        findKey(LOCATION_STOCK_GROUP_MAP, group => group === stockGroup),
      ];
      const props = { ...bagItemProps, item, modifiers };
      return <BagItem key={item.id + item.code} {...props} />;
    };

    // Bag items can either be:
    // - a multi-dimensional array so they can be grouped by the stockGroup key.
    let nestedBagItems: Element<*>[][];
    // - a flat array of bag items.
    let bagItems: Element<*>[];

    if (splitItemsByDeliveryTimes) {
      nestedBagItems = items.reduce(
        (itemsByStockGroup: Element<*>[][], item: BagItemType) => {
          // Stock group should always exist but if it doesn't for some reason then set it to unknown so it won't be
          // available for upgrades.
          let stockGroup = item.stockGroup || LOCATION_STOCK_GROUP_MAP.unknown;

          // If we are sending the items abroad then group in shop and in warehouse together. Assume everything is in
          // warehouse.
          if (
            !isDomestic &&
            item.stockGroup === LOCATION_STOCK_GROUP_MAP.shop
          ) {
            stockGroup = LOCATION_STOCK_GROUP_MAP.warehouse;
          }

          const bagItem = getBagItemElement(item, stockGroup);
          itemsByStockGroup[stockGroup].push(bagItem);
          return itemsByStockGroup;
        },
        [[], [], [], []]
      );
    } else {
      bagItems = items.map(item => {
        // Stock group should always exist but if it doesn't for some reason then set it to unknown so it won't be
        // available for upgrades.
        const stockGroup = item.stockGroup || LOCATION_STOCK_GROUP_MAP.unknown;
        return getBagItemElement(item, stockGroup);
      });
    }
    const handleScroll = () => {
      forceCheck();
    };
    return (
      <table
        className={applyModifiers(STYLE_PREFIX, isEditable && ['is-editable'])}
        summary={formatMessage(messages.summary)}
      >
        <thead className="c-bag__header u-hidden">
          <tr>
            <th scope="column">
              <FormattedMessage id="bag.product" defaultMessage="Product" />
            </th>
            {isEditable && (
              <th scope="column">
                <FormattedMessage
                  id="bag.quantity.select"
                  defaultMessage="Quantity - select"
                />
              </th>
            )}

            {isEditable && (
              <th scope="column">
                <FormattedMessage id="bag.remove" defaultMessage="Remove" />
              </th>
            )}
            {!isEditable && (
              <th scope="column">
                <FormattedMessage id="bag.quantity" defaultMessage="Quantity" />
              </th>
            )}
            <th scope="column">
              <FormattedMessage id="bag.price" defaultMessage="Price" />
            </th>
          </tr>
        </thead>
        <tbody
          onScroll={handleScroll}
          className={`${
            this.props.location === 'bagPage'
              ? 'c-bag__main'
              : 'c-bag__main--newStyling'
          }`}
        >
          {splitItemsByDeliveryTimes && nestedBagItems
            ? [
                // Get all the items currently located in the warehouse.
                ...getItemsLocatedIn(
                  'warehouse',
                  nestedBagItems,
                  titleMessages,
                  upSellPremiumPostage,
                  onUpSellPostage
                ),

                // Get all the items currently located in a shop.
                // These items will not be available for premium delivery upgrades.
                ...getItemsLocatedIn('shop', nestedBagItems, titleMessages),

                // Get all the items that we know are available, but we cannot guarantee when they will be dispatched.
                ...getItemsLocatedIn('unknown', nestedBagItems, titleMessages),
              ]
            : bagItems}
        </tbody>
        <tfoot className="c-bag__footer">
          {
            // TODO: add in discounts etc
            // <tr className="c-bag__total c-bag__total--discount">
            //   <td className="c-bag__total__value" colSpan="4">10% partner promotional discount:
            //     <span className="c-bag__total__price c-bag__price"> -£7.15</span>
            //   </td>
            // </tr>
          }
          {giftVouchers.length > 0 && (
            <tr className="c-bag__total">
              <td className="c-bag__total__value" colSpan={cols}>
                <FormattedMessage
                  id="bag.gift-voucher-applied"
                  defaultMessage="Gift Vouchers Applied: "
                />
                <span className="c-bag__total__price c-bag__price">&nbsp;</span>
              </td>
            </tr>
          )}
          {giftVouchers.map((giftVoucher, index) => {
            const value = 0 - giftVoucher.value;
            const gv = giftVoucher.reference || '';
            const giftVoucherReference = `${gv.substring(0, 4)}-${gv.substring(
              4,
              8
            )}-${gv.substring(8, 12)}-${gv.substring(12, 16)}`;
            return (
              <tr
                className="c-bag__total c-bag__total--gift-voucher"
                key={index}
              >
                <td className="c-bag__total__value" colSpan={cols}>
                  <span
                    id={`${giftVoucherReference.replace(
                      /-/g,
                      ''
                    )}-bag-gift-voucher`}
                    className="gift-voucher-reference"
                  >
                    {giftVoucherReference}
                  </span>
                  <strong className="c-bag__total__price c-bag__price">
                    <FormattedNumber
                      value={value}
                      currency={currency}
                      style="currency"
                    />
                  </strong>
                </td>
              </tr>
            );
          })}
          <tr className="c-bag__total c-bag__sub__total">
            <td className="c-bag__total__value" colSpan={cols}>
              <FormattedMessage
                id="bag.total"
                defaultMessage="Total (excluding delivery):"
              />
              <strong className="c-bag__total__price c-bag__price">
                <FormattedNumber
                  value={subTotal}
                  currency={currency}
                  style="currency"
                />
              </strong>
            </td>
          </tr>
          {postage != null && showPostage && (
            <tr className="c-bag__total c-bag__postage__total">
              <td className="c-bag__total__postage" colSpan={cols}>
                <FormattedMessage
                  id="bag.delivery"
                  defaultMessage="Delivery:"
                />
                <strong className="c-bag__total__price c-bag__price">
                  {discount && postage - discount <= 0 ? (
                    <span className="c-badge c-badge--large">
                      <FormattedMessage
                        id="delivery-methods.free"
                        defaultMessage="Free"
                      />
                    </span>
                  ) : (
                    <FormattedNumber
                      value={postage - discount}
                      currency={currency}
                      style="currency"
                    />
                  )}
                </strong>
              </td>
            </tr>
          )}
          {!isEditable && usTaxAmount !== null && showUSTax && (
            <tr className="c-bag__total c-bag__grand__total">
              <td className="c-bag__total__value" colSpan={cols}>
                <FormattedMessage id="bag.tax" defaultMessage="Tax:" />
                <strong className="c-bag__total__tax__price c-bag__tax">
                  <FormattedNumber
                    value={usTaxAmount}
                    currency={currency}
                    style="currency"
                  />
                </strong>
              </td>
            </tr>
          )}
          {total != null && showPostage && (
            <tr className="c-bag__total c-bag__grand__total">
              <td className="c-bag__total__value" colSpan={cols}>
                <FormattedMessage
                  id="bag.grand-total"
                  defaultMessage="Grand total:"
                />
                <strong className="c-bag__total__price c-bag__price">
                  <FormattedNumber
                    value={locale === 'en-US' ? totalUSAmount : total}
                    currency={currency}
                    style="currency"
                  />
                </strong>
              </td>
            </tr>
          )}
          {promotionMessages.map(({ message }) => (
            <tr className="c-bag__promotions" key={`row-${message.id}`}>
              <td colSpan={cols} key={`cell-${message.id}`}>
                <PromotionMessage content={message} key={message.id} />
              </td>
            </tr>
          ))}
        </tfoot>
      </table>
    );
  }
}

export default injectIntl(Bag);
