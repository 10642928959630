import React from 'react';

/**
 * @returns {Element}
 * Returns Payment Methods - Currently hard coded
 */
const NewFooterAcceptedPaymentMethods = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="56"
        fill="none"
        viewBox="0 0 40 26"
      >
        <path fill="#fff" d="M0 0h40v26H0z" />
        <path
          fill="#1434CB"
          d="m15.9 7.7-4.43 10.6h-2.9l-2.2-8.47c-.13-.52-.25-.71-.65-.93C5.05 8.55 3.96 8.2 3 8l.07-.32h4.67c.6 0 1.13.4 1.27 1.09l1.15 6.14 2.86-7.23h2.89Zm11.39 7.15c0-2.8-3.88-2.96-3.85-4.21 0-.38.37-.79 1.16-.9a5.2 5.2 0 0 1 2.71.48l.48-2.25a7.4 7.4 0 0 0-2.57-.47c-2.71 0-4.62 1.44-4.64 3.51-.02 1.53 1.36 2.38 2.4 2.9 1.08.51 1.44.85 1.43 1.31 0 .71-.85 1.03-1.64 1.04-1.39.02-2.19-.37-2.82-.67l-.5 2.33c.64.29 1.82.55 3.05.56 2.89 0 4.78-1.42 4.79-3.63Zm7.17 3.46H37L34.78 7.7h-2.34c-.53 0-.98.3-1.17.78l-4.12 9.84h2.88l.57-1.58h3.53l.33 1.58Zm-3.07-3.76 1.45-3.99.83 4H31.4ZM19.83 7.7l-2.27 10.62h-2.74L17.09 7.7h2.74Z"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="56"
        viewBox="0 0 40 26"
      >
        <path fill="#fff" d="M0 0h40v26H0z" />
        <path fill="#F06022" d="M16.13 19.29h7.74V6.7h-7.74v12.58z" />
        <path
          fill="#EA1D25"
          d="M16.93 13A7.93 7.93 0 0 1 20 6.71a8.02 8.02 0 0 0-10.65.65 7.96 7.96 0 0 0 0 11.28 8.02 8.02 0 0 0 10.65.65A8.02 8.02 0 0 1 16.93 13"
        />
        <path
          fill="#F79D1D"
          d="M33 13c0 2.12-.84 4.15-2.34 5.65a8.1 8.1 0 0 1-10.66.64A8.05 8.05 0 0 0 23.07 13 7.96 7.96 0 0 0 20 6.71a8.02 8.02 0 0 1 10.66.64A7.93 7.93 0 0 1 33 13"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="56"
        viewBox="0 0 40 26"
      >
        <path fill="#016FD0" d="M0 26h40V0H0v26z" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M30.69 13.63v1.64h-4.17v1.14h4.07v1.64h-4.07v1.12h4.17v1.66l3.38-3.6-3.38-3.6zm-1.1-6.14-1.4-3.19h-4l-4.1 9.32h3.33v8.27l10.28.01 1.61-1.8 1.63 1.8H40v-2.63l-1.92-2.06L40 15.16v-2.59l-1.93.01V7.6l-1.81 4.98H34.5l-1.86-5v5h-4.2l-.6-1.46h-3.3l-.6 1.46h-2.22l3.23-7.27V5.3h2.55l3.19 7.21V5.3l3.1.01 1.6 4.47 1.62-4.48H40v-1h-3.77l-.85 2.39-.85-2.39h-4.94v3.19zm-5.06 6.11v7.27h6.16v-.01h2.54l2.1-2.32 2.12 2.32H40v-.1l-3.34-3.53L40 13.65v-.05h-2.52l-2.1 2.3-2.08-2.3h-8.77zm.7-4.11.96-2.31.97 2.31h-1.93z"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="56"
        fill="none"
        viewBox="0 0 40 26"
      >
        <path fill="#fff" d="M0 0h40v26H0z" />
        <path fill="#7773B4" d="M16.13 19.29h7.74V6.7h-7.74v12.58z" />
        <path
          fill="#EA1D25"
          d="M16.93 13A7.93 7.93 0 0 1 20 6.71a8.02 8.02 0 0 0-10.65.65 7.96 7.96 0 0 0 0 11.28 8.02 8.02 0 0 0 10.65.65A8.02 8.02 0 0 1 16.93 13"
        />
        <path
          fill="#139FDA"
          d="M33 13c0 2.12-.84 4.15-2.34 5.65a8.1 8.1 0 0 1-10.66.64A8.05 8.05 0 0 0 23.07 13 7.96 7.96 0 0 0 20 6.71a8.02 8.02 0 0 1 10.66.64A7.93 7.93 0 0 1 33 13"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="50"
        height="56"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path d="M269 4190 c-60 -10 -136 -50 -176 -92 -18 -18 -45 -60 -60 -93 l-28 -60 0 -1385 0 -1385 28 -60 c50 -108 127 -165 248 -185 89 -14 4469 -14 4558 0 121 20 198 77 248 185 l28 60 0 1385 0 1385 -28 60 c-50 108 -127 165 -248 185 -76 12 -4498 12 -4570 0z m4626 -131 c35 -18 59 -39 80 -74 l30 -48 0 -1377 0 -1377 -30 -48 c-21 -35 -45 -56 -80 -74 l-49 -26 -2261 -3 c-1608 -2 -2273 1 -2305 8 -61 15 -112 52 -141 103 l-24 42 0 1375 0 1375 24 42 c29 50 80 89 136 102 24 5 921 8 2305 8 l2266 -2 49 -26z" />
          <path d="M1361 3269 c-89 -44 -148 -130 -153 -223 l-3 -41 40 3 c105 8 204 118 221 246 6 44 6 46 -18 46 -14 0 -53 -14 -87 -31z" />
          <path d="M1930 2626 l0 -586 95 0 95 0 0 199 0 199 183 4 183 3 65 32 c79 39 140 102 177 181 24 51 27 69 27 167 0 100 -3 116 -28 170 -36 78 -104 146 -181 182 l-61 28 -277 3 -278 3 0 -585z m528 398 c78 -39 107 -93 107 -199 0 -70 -4 -86 -29 -129 -22 -38 -39 -53 -83 -73 -47 -21 -73 -26 -193 -30 l-140 -6 0 232 0 231 143 0 c134 0 146 -2 195 -26z" />
          <path d="M1389 2990 c-20 -5 -59 -18 -88 -30 -67 -26 -91 -25 -176 5 -38 13 -88 25 -111 25 -127 0 -245 -88 -295 -221 -72 -188 10 -493 180 -672 70 -74 120 -83 236 -42 87 31 113 31 200 0 80 -28 133 -32 174 -10 50 25 130 126 179 224 45 91 46 94 27 105 -11 6 -43 34 -71 61 -112 108 -112 266 0 382 l53 54 -32 35 c-61 64 -192 104 -276 84z" />
          <path d="M3077 2899 c-56 -13 -128 -53 -164 -91 -35 -37 -72 -111 -73 -145 0 -22 3 -23 84 -23 l84 0 12 31 c7 16 29 42 48 57 29 22 47 27 106 30 118 5 169 -36 182 -150 l7 -56 -109 -6 c-225 -13 -303 -32 -372 -91 -81 -68 -101 -197 -45 -296 93 -164 341 -184 482 -39 l41 42 0 -61 0 -61 91 0 90 0 -3 328 c-3 319 -4 328 -26 374 -53 108 -171 169 -321 167 -42 -1 -93 -5 -114 -10z m279 -536 c-14 -119 -79 -184 -190 -191 -115 -7 -176 31 -176 112 0 98 74 130 304 135 l69 1 -7 -57z" />
          <path d="M3610 2889 c0 -7 68 -201 151 -432 l152 -420 -17 -52 c-11 -35 -28 -64 -52 -84 -30 -27 -42 -31 -94 -31 l-60 0 0 -75 0 -75 73 0 c142 0 226 46 286 159 24 46 371 996 371 1017 0 2 -42 4 -94 4 l-94 0 -35 -112 c-20 -62 -68 -218 -108 -345 -40 -128 -74 -232 -75 -230 -3 2 -147 462 -210 670 -5 15 -19 17 -100 17 -64 0 -94 -4 -94 -11z" />
        </g>
      </svg>
      <svg
        enableBackground="new 0 0 512 512"
        height="56"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 512 512"
        width="50"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <g>
            <path
              d="M481.874,102.698c13.854,0,25.126,11.271,25.126,25.126v257.899c0,13.854-11.271,25.126-25.126,25.126    H30.143c-13.854,0-25.126-11.271-25.126-25.126V127.824c0-13.854,11.271-25.126,25.126-25.126H481.874 M481.874,97.698H30.143    c-16.638,0-30.126,13.488-30.126,30.126v257.899c0,16.64,13.488,30.126,30.126,30.126h451.731    c16.64,0,30.126-13.486,30.126-30.126V127.824C512,111.186,498.513,97.698,481.874,97.698L481.874,97.698z"
              fill="#1B557D"
            />
          </g>
          <g>
            <path
              d="M380.913,219.579c-10.783,0-19.312,2.862-25.833,4.165l-2.01,16.392    c3.263-1.562,13.545-4.422,22.076-4.684c8.528-0.261,13.544,1.559,11.791,8.847c-25.336,0-42.396,5.202-45.907,21.598    c-5.021,28.104,25.841,27.322,38.13,15.092l-1.507,6.767h22.576l9.787-44.757C414.025,224,396.716,219.32,380.913,219.579z     M382.418,265.375c-1.254,5.982-6.017,8.589-11.786,8.852c-5.016,0.256-9.535-4.17-6.272-9.371    c2.513-4.422,9.535-5.465,13.799-5.465c1.753,0,3.769,0,5.519,0C383.171,261.473,382.915,263.297,382.418,265.375z"
              fill="#107DB0"
            />
            <polygon
              fill="#107DB0"
              points="429.327,201.88 410.649,287.754 433.583,287.754 452.491,201.88 429.327,201.88   "
            />
            <path
              d="M323.965,202.144h-41.985l-18.605,85.61h24.387l6.287-28.363h17.35c17.093,0,31.174-10.408,34.947-28.104    C350.614,211.252,336.033,202.144,323.965,202.144z M323.215,231.287c-1.513,6.506-8.05,11.71-14.338,11.71h-11.311l5.03-23.418    h12.066C320.952,219.579,324.722,224.783,323.215,231.287z"
              fill="#107DB0"
            />
            <path
              d="M161.04,219.579c-10.655,0-19.083,2.862-25.277,4.165l-1.981,16.392    c2.973-1.562,13.136-4.422,21.557-4.684c8.427-0.261,13.384,1.559,11.895,8.847c-25.026,0-41.876,5.202-45.346,21.598    c-4.958,28.104,25.521,27.322,37.414,15.092l-1.484,6.767h22.55l9.663-44.757C193.996,224,176.648,219.32,161.04,219.579z     M162.772,265.375c-1.238,5.982-6.195,8.589-11.894,8.852c-4.957,0.256-9.168-4.17-5.947-9.371    c2.477-4.422,9.415-5.465,13.381-5.465c1.983,0,3.715,0,5.699,0C163.517,261.473,163.021,263.297,162.772,265.375z"
              fill="#1B557D"
            />
            <polygon
              fill="#1B557D"
              points="199.855,220.809 222.402,220.809 226.04,260.68 248.103,220.809 271.371,220.809 217.796,316.229     192.582,316.229 209.064,288.175 199.855,220.809   "
            />
            <path
              d="M104.955,202.144H62.86l-18.652,85.61h24.705l6.048-28.363h17.642c16.888,0,31.006-10.408,34.785-28.104    C131.671,211.252,117.305,202.144,104.955,202.144z M104.198,231.287c-1.512,6.506-7.813,11.71-14.115,11.71H78.488l5.294-23.418    H95.88C101.932,219.579,105.963,224.783,104.198,231.287z"
              fill="#1B557D"
            />
            <path
              d="M457.442,212.979v-10.123h-3.777V201.5h9.1v1.356h-3.797v10.123H457.442z"
              fill="#1B557D"
            />
            <path
              d="M464.27,212.979V201.5h2.273l2.725,8.125c0.251,0.756,0.434,1.322,0.549,1.698    c0.132-0.417,0.337-1.03,0.611-1.839l2.756-7.984h2.044v11.479h-1.461v-9.61l-3.351,9.61h-1.367l-3.318-9.767v9.767H464.27z"
              fill="#1B557D"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default NewFooterAcceptedPaymentMethods;
